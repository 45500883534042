import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { UserState, USER_FOUND } from 'redux-oidc';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import axios from '../axios';

const handleAuthInit = (state: { oidc: UserState }) => {
  try {
    const accessToken = state.oidc?.user?.access_token;
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } catch (error) {
    console.error(error);
  }
};

export const oidcEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<{ oidc: UserState }>
): Observable<any> =>
  action$.pipe(
    ofType(USER_FOUND),
    withLatestFrom(state$),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    map(([_, state]) => {
      handleAuthInit(state);
      return { type: 'axios/configured', defaults: axios.defaults.headers };
    })
  );
