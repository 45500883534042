import StyledEngineProvider from '@material-ui/core/StyledEngineProvider';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import 'nprogress/nprogress.css';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { loadUser, OidcProvider, processSilentRenew } from 'redux-oidc';
import App from './App';
// import './__mocks__';
import store from './store';
import userManager from './store/userManager';

async function startApp() {
  await loadUser(store, userManager);
  processSilentRenew();

  ReactDOM.render(
    <StrictMode>
      <HelmetProvider>
        <ReduxProvider store={store}>
          <OidcProvider userManager={userManager} store={store}>
            <StyledEngineProvider injectFirst>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </LocalizationProvider>
            </StyledEngineProvider>
          </OidcProvider>
        </ReduxProvider>
      </HelmetProvider>
    </StrictMode>,
    document.getElementById('root')
  );
}

if (window.location.pathname === '/silent_renew') {
  try {
    console.info('perform silent renew');
    userManager.signinSilentCallback();
  } catch (error) {
    // Sentry.captureException(err);
    console.error('silent renew error', error);
  }
} else {
  startApp();
}
