function getTenant() {
  switch (window.location.hostname) {
    case 'mediq.prescription.apps.halloalberta.de':
      return 'mediq';
    case 'livica.prescription.apps.halloalberta.de':
      return 'livica';
    case 'itlabs.prescription.apps.halloalberta.de':
    default:
      return 'itlabs';
  }
}

const TENANT = getTenant();

const config = {
  mediq: {
    baseUrl: 'https://mediq.prescription.k8s.api.halloalberta.de',
    mainColor: '#006983',
    clientId: '3cea386a-5b6f-4073-9fc9-95cc0d6df6b1',
  },
  livica: {
    baseUrl: 'https://livica.prescription.k8s.api.halloalberta.de',
    mainColor: '#ffa400',
    clientId: 'af8a34cd-0d26-4eeb-a3b6-0f1c756485f0',
  },
  itlabs: {
    baseUrl: 'https://itlabs.prescription.k8s.api.halloalberta.de',
    mainColor: '#336666',
    clientId: '6c067ca5-f1e8-4354-bd21-271cad573641',
  },
};

function getBaseUrl() {
  const isDevEnvironment = window.location.hostname === 'localhost';
  if (isDevEnvironment) {
    return 'http://localhost:55011';
  }
  return config[TENANT].baseUrl;
}

export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
};

export const BASEURL = getBaseUrl();

export const MAIN_COLOR = config[TENANT].mainColor;

export const CLIENT_ID = config[TENANT].clientId;
