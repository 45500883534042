import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import type { TypedUseSelectorHook } from 'react-redux';
import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from 'react-redux';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { oidcEpic } from './oidcEpic';
import rootReducer from './rootReducer';

const rootEpic = combineEpics(oidcEpic);
const epicMiddleware = createEpicMiddleware({
  dependencies: {},
});

const store = configureStore({
  reducer: rootReducer,
  // devTools: process.env.REACT_APP_ENABLE_REDUX_DEV_TOOLS === 'true',
  devTools: true,
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: ['oidc'],
        ignoredActions: ['redux-oidc/LOADING_USER', 'redux-oidc/USER_FOUND'],
      },
    }),
    epicMiddleware,
  ],
});

epicMiddleware.run(rootEpic as any);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export const useDispatch = () => useReduxDispatch<AppDispatch>();

export default store;
