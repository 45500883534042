import { createStyles, makeStyles } from '@material-ui/core/styles';
import type { FC } from 'react';

const useStyles = makeStyles(
  () =>
    // eslint-disable-next-line implicit-arrow-linebreak
    createStyles({
      '@global': {
        '*': {
          boxSizing: 'border-box',
          margin: 0,
          padding: 0,
        },
        html: {
          '-webkit-font-smoothing': 'antialiased',
          '-moz-osx-font-smoothing': 'grayscale',
          height: '100%',
          width: '100%',
        },
        body: {
          height: '100%',
          width: '100%',
        },
        '#root': {
          height: '100%',
          width: '100%',
        },
      },
    })
  // eslint-disable-next-line function-paren-newline
);

const GlobalStyles: FC = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
