import { UserManagerSettings, WebStorageStateStore } from 'oidc-client';
import { CLIENT_ID } from '../constants';

export default function getOAuthConfig(): UserManagerSettings {
  const baseUrl = `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ''
  }`;
  return {
    client_id: CLIENT_ID,
    redirect_uri: `${baseUrl}/callback`,
    response_type: 'code',
    post_logout_redirect_uri: `${baseUrl}/logout`,
    authority: 'https://auth.halloalberta.de',
    silent_redirect_uri: `${baseUrl}/silent_renew`,
    automaticSilentRenew: true,
    scope: 'openid',
    userStore: new WebStorageStateStore({ store: window.localStorage }),
  };
}
