import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { UserState } from 'redux-oidc';
import { useSelector } from '../store';

interface AuthGuardProps {
  children: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = (props) => {
  const { children } = props;
  const { isLoadingUser, user } = useSelector(
    (state: { oidc: UserState }) => state.oidc
  );
  const hasUser = !!user && user.expired !== true;
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (isLoadingUser === false && !hasUser) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    return <Navigate to="login" />;
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
