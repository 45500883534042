import axios from 'axios';
import AxiosMockAdapter from 'axios-mock-adapter';
import { BASEURL } from './constants';

console.info('using api endpoint', BASEURL);

const axiosInstance = axios.create({ baseURL: BASEURL });

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || 'Something went wrong'
    )
);

// TODO figure out why this breaks if we use axiosInstance here
// for the mock to work we need to use axiosInstance here
export const mock = new AxiosMockAdapter(axios, { delayResponse: 0 });

export default axiosInstance;
