import { ThemeProvider } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import { FC } from 'react';
import { PartialRouteObject } from 'react-router';
import { useNavigate, useRoutes } from 'react-router-dom';
import { CallbackComponent } from 'redux-oidc';
import GlobalStyles from './components/GlobalStyles';
import { THEMES } from './constants';
import './i18n';
import routes from './routes';
import userManager from './store/userManager';
import { createTheme } from './theme';

const App: FC = () => {
  const navigate = useNavigate();
  const RedirectToOAuth: React.FC = () => {
    userManager.signinRedirect();
    return null;
  };

  const authRoutes: PartialRouteObject[] = [
    { path: 'login', element: <RedirectToOAuth /> },
    { path: 'logout', element: <RedirectToOAuth /> },
    {
      path: 'callback',
      element: (
        <CallbackComponent
          userManager={userManager}
          successCallback={() => navigate('/')}
        >
          <div>redirecting...</div>
        </CallbackComponent>
      ),
    },
  ];

  const content = useRoutes([...authRoutes, ...routes]);
  const theme = createTheme({
    responsiveFontSizes: true,
    theme: THEMES.LIGHT,
  });

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider dense maxSnack={3}>
        <GlobalStyles />
        {content}
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
